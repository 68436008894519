import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import { ROLE } from "../enums/role";

const useUserRole = () => {
    const role = useSelector((state: RootState) => state.user.role);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isAdvokat, setIsAdvokat] = useState(false);
    const [isMgsi, setIsMgsi] = useState(false);
    const [isGeodet, setIsGeodet] = useState(false);
    const [isBank, setIsBank] = useState(false);
    const [isInfoCentar, setIsInfoCentar] = useState(false);
    const [isNekretnine, setIsNekretnine] = useState(false);
    const [isMobileUser, setIsMobileUser] = useState(false);
    const [isIzvrsitelj, setIsIzvrsitelj] = useState(false);
    const [isOstina, setIsOpstina] = useState(false);

    useEffect(() => {
        setIsSuperAdmin(role === ROLE.SUPERADMIN);
        setIsAdmin(role === ROLE.ADMIN || role === ROLE.SUPERADMIN);
        setIsAdvokat(role === ROLE.ADVOKAT);
        setIsMgsi(role === ROLE.MGSI);
        setIsMobileUser(role === ROLE.MOBILE);
        setIsInfoCentar(role === ROLE.INFOCENTAR);
        setIsOpstina(role === ROLE.OPSTINA);
        setIsNekretnine(role === ROLE.NEKRETNINE);
        setIsBank(role === ROLE.BANK);
        setIsIzvrsitelj(role === ROLE.IZVRSITELJ);
        setIsGeodet(role === ROLE.GEODET);
    }, [role]);

    return {
        isSuperAdmin,
        isAdmin,
        isAdvokat,
        isMgsi,
        isMobileUser,
        isGeodet,
        isBank,
        isInfoCentar,
        isNekretnine,
        isIzvrsitelj,
        isOstina,
    };
};

export default useUserRole;
