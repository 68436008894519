import { SetStateAction, useEffect, useState } from "react";
import { katastarskeOpstineOptions } from "../../../register/register";
import KatakomInput from "../../shared/KatakomInput";
import KatakomButton from "../../shared/KatakomButton";
import KatakomSelect, { SelectOption } from "../../shared/KatakomSelect";
import KatakomTable from "../../shared/KatakomTable";
import { Column, KatakomPaginate, Row } from "../style";
import api from "../../../services/api";
import useMobileCheck from "../../../hooks/useMobileChecker";
import { useLocation } from "react-router-dom";
import { RowWrapper } from "../katastar/pretragaPoImenu/PretragaPoImenuLayout";

export const NelegalniObjektiDisplay = () => {
    const location = useLocation(); // Get the current location
    const queryParams = new URLSearchParams(location.search); // Parse query parameters
    const [matBrOpstine, setMatBrOpstine] = useState<string>(queryParams.get("brOpstine") || "");
    const [koId, setKoId] = useState<string>(queryParams.get("koid") || "");
    const [brojParcele, setBrojParcele] = useState<string>(queryParams.get("brojParcele") || "");
    const [data, setData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const isMobile = useMobileCheck();

    const itemsPerPage = 7;

    const fatchData = async (offset: number) => {
        let queryParams: any = {};

        if (matBrOpstine !== "") {
            queryParams.matBrOpstine = matBrOpstine;
        }

        if (koId !== "") {
            queryParams.koId = koId;
        }

        if (brojParcele !== "") {
            queryParams.brojParcele = brojParcele;
        }

        try {
            const res = await api.get(`api/v1/pretraga-nelegalnih-objekata`, {
                ...queryParams,
                limit: itemsPerPage,
                offset,
            });

            setData(res?.data.data);
            setTotalPages(Math.ceil(res?.data.meta.total / itemsPerPage));
        } finally {
        }
    };

    useEffect(() => {
        if (data.length !== 0) {
            const currentPageNumber = Math.floor(currentPage * itemsPerPage);
            fatchData(currentPageNumber);
        }
    }, [currentPage]);

    const handlePageClick = (selected: { selected: SetStateAction<number> }) => {
        setCurrentPage(selected.selected);
    };

    const handleSearchClick = () => {
        fatchData(currentPage);
        setCurrentPage(0);
    };

    useEffect(() => {
        if (koId && matBrOpstine && brojParcele) handleSearchClick();
    }, []);

    const columns = [
        { label: "Објекат Ид", value: "objekatId" },
        { label: "Више парцела", value: "naViseParcela" },
        { label: "Број парцеле", value: "brojParcele" },
        { label: "Извор података геометрија", value: "izvorPodatakaGeometrija" },
        { label: "Епоха промена", value: "epohaPromena" },
        { label: "Врста промена", value: "vrstaPromene" },
        { label: "Статус Објекта", value: "statusObjekta" },
        { label: "Извор статуса", value: "izvorStatusa" },
        { label: "Коментар", value: "komentar" },
        { label: "", value: "lokacija" },
    ];

    return (
        <Column>
            <Column>
                {isMobile ? (
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto" }}>
                        <Row>
                            <KatakomSelect
                                options={katastarskeOpstineOptions}
                                value={koId}
                                name="koId"
                                size="m"
                                label="Општина - Катастарска општина*"
                                error={false}
                                onChange={(option: SelectOption) => {
                                    const brOpstine = option.value.split("_")[2];
                                    const koid = option.value.split("_")[3];

                                    setMatBrOpstine(brOpstine);
                                    setKoId(koid);
                                }}
                            />
                        </Row>
                        <Row>
                            <KatakomInput
                                label="Број парцеле"
                                value={brojParcele}
                                name={"brojParcele"}
                                error={false}
                                onChange={(e) => {
                                    setBrojParcele(e.target.value);
                                }}
                                type={"text"}
                                onEnter={handleSearchClick}
                            />
                        </Row>
                        <Row>
                            <KatakomButton
                                label={"ПРЕТРАЖИ"}
                                onClick={handleSearchClick}
                                size="xl"
                                style={{ margin: "auto", marginBottom: "20px" }}
                            />
                        </Row>
                    </div>
                ) : (
                    <RowWrapper style={{ marginTop: "20px" }}>
                        <KatakomSelect
                            options={katastarskeOpstineOptions}
                            value={koId}
                            name="koId"
                            size="m"
                            label="Општина - Катастарска општина*"
                            error={false}
                            onChange={(option: SelectOption) => {
                                const brOpstine = option.value.split("_")[2];
                                const koid = option.value.split("_")[3];

                                setMatBrOpstine(brOpstine);
                                setKoId(koid);
                            }}
                        />
                        <KatakomInput
                            label="Број парцеле"
                            value={brojParcele}
                            name={"brojParcele"}
                            error={false}
                            onChange={(e) => {
                                setBrojParcele(e.target.value);
                            }}
                            type={"text"}
                            onEnter={handleSearchClick}
                        />
                        <KatakomButton label={"ПРЕТРАЖИ"} onClick={handleSearchClick} style={{ marginTop: "40px" }} />
                    </RowWrapper>
                )}

                {data.length > 0 ? (
                    <div style={{ margin: "15px", marginBottom: "50px" }}>
                        <KatakomTable columns={columns} data={data} onEdit={() => {}} onDelete={() => {}} />{" "}
                    </div>
                ) : null}
            </Column>
            {data.length > 0 && (
                <KatakomPaginate
                    previousLabel={isMobile ? "<<" : "Претходно"}
                    nextLabel={isMobile ? ">>" : `Следеће`}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={isMobile ? 1 : 2}
                    pageRangeDisplayed={isMobile ? 1 : 5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            )}
        </Column>
    );
};
