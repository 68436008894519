export enum ROLE {
    ADMIN = "admin",
    SUPERADMIN = "superadmin",
    ADVOKAT = "advokat",
    MGSI = "mgsi",
    MOBILE = "mobile",
    INFOCENTAR = "infocentar",
    BANK = "bank",
    GEODET = "geodet",
    NEKRETNINE = "nekretnine",
    IZVRSITELJ = "izvrsitelj",
    OPSTINA = "opstina",
}
