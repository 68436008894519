import React from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../../../constants/constants";
import api from "../../../../services/api";

export interface Person {
    ime: string;
    imeRoditelja: string;
    izvorPodatkaCir: string;
    izvorPodatkaID: number;
    izvorPodatkaLat: string;
    liceID: number;
    liceMatBroj: string;
    liceVrstaCir: string;
    liceVrstaID: number;
    liceVrstaLat: string;
    mestoNaziv: string;
    naziv: string;
    prezime: string;
}

interface LiceCardProps {
    person: Person;
    setLiceNepokretnostiData: (value: any) => void;
    setOpenedCard: (value: boolean) => void;
}

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${themeColors.quinary};
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px #00000040;
    padding: 20px;
    width: 250px;
    height: 300px;
    color: ${themeColors.secondary};
    margin: 10px;
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const PersonIcon = styled.img`
    width: 70px;
`;

const Name = styled.h3`
    margin: 0;
`;

const Info = styled.p`
    margin: 10px 0;
`;

const Button = styled.button`
    border-radius: 5px;
    background-color: ${themeColors.quaternary};
    color: ${themeColors.secondary};
    padding: 10px 20px;
    border: none;
    outline: none;
    cursor: pointer;
`;

export const LiceCard: React.FC<LiceCardProps> = ({ person, setLiceNepokretnostiData, setOpenedCard }) => {
    const fatchData = async (jmbg: string) => {
        const res = await api.get(`api/v1/pretraga-lica/?jmbg=${jmbg}`);
        if (res?.data?.Lica?.Lice?.length === 0) {
            setLiceNepokretnostiData(null);
            setOpenedCard(false);
            return;
        }

        setLiceNepokretnostiData({
            ...res?.data,
        });
        setOpenedCard(true);
    };
    return (
        <CardContainer onClick={() => {}}>
            <CardHeader>
                <PersonIcon src={`${themeIcon}/person.png`} />
            </CardHeader>

            <Body>
                <Info>{person.naziv}</Info>
                <Info>{person.mestoNaziv}</Info>
                <Info>{person.liceMatBroj}</Info>
                <Info>
                    <Button
                        onClick={() => {
                            fatchData(person.liceMatBroj);
                        }}
                    >
                        Више
                    </Button>
                </Info>
            </Body>
        </CardContainer>
    );
};
